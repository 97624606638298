export const dipdup = {
    uri: {
        graphq: "https://juster.dipdup.net/v1/graphql",
        subscription: "wss://juster.dipdup.net/v1/graphql",
    },
}

export const supportedSymbols = {
    "ETH-USD": { description: "Ethereum / U.S. Dollar" },
    "XTZ-USD": { description: "Tezos / U.S. Dollar" },
    "BTC-USD": { description: "Bitcoin / U.S. Dollar" },
}
